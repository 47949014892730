<template>
  <div>
    <div class="modal fade" id="popupModal" tabindex="-1" aria-labelledby="itemsModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="itemsModalLabel">Items List</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="number">No</th>
                  <th>Name</th>
                  <th class="number">Stock</th>
                  <th class="number">Price</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in itemInfos.items" :key="item.id" @click="displayItemLocation(item)">
                  <td class="number">{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td class="number">{{ item.stock }}</td>
                  <td class="number">{{ item.price }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { mapGetters, mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['getCustomerPosition','getItemCoordinates']),
    show() {
        this.modal.show();
    },
    hide(){
        this.modal.hide();
    },
    async displayItemLocation(item) {
      this.modal.hide();
      await this.getItemCoordinates(item.id);
      this.$emit('getItemCoordinates');
    }
  },
  mounted() {  
    this.modal = new Modal(document.getElementById('popupModal'));  
  }, 
  computed: {
    ...mapGetters(['itemInfos'])
  }
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}

.number {
  text-align: right;
}
</style>
