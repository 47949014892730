import axiosInstance from '../api.js';

const itemServices = {
  getItemsInfo(name) {
    return axiosInstance.get('api/v1/items?name='+ name);
  },
  getCustomerPosition(userId) {
    return axiosInstance.get('api/v1/user/location/' + userId);
  },
  getItemCoordinates(itemId){
    return axiosInstance.get('api/v1/item/locations/' + itemId);
  },
  getItem(itemId) {
    return axiosInstance.get('api/v1/item/' + itemId);
  }
};

export default itemServices;