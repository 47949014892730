import CryptoJS from 'crypto-js';

const cryptoJS = {
  encrypt(text, secretKey) {
    return CryptoJS.AES.encrypt(text, secretKey).toString();
  },
  decrypt(ciphertext, secretKey) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
export default cryptoJS;
