import { createStore } from 'vuex';
import userServices from '../services/userService.js';
import itemServices from '../services/itemService.js';

const store = createStore({
  state() {
    return {
      user: null,
      isLogin: false,
      token: '',
      items:[],
      customerPosition: [28,161],
      itemCoordinates: null
    };
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      state.isLogin = true;
      state.token = user.token
    },
    SET_LOCATION(state, customerPosition) {
      state.customerPosition = customerPosition;
    },
    SET_ITEMS(state, items) {
      state.items = items;
    },
    SET_ITEM_COORDINATES(state, itemCoordinates){
      state.itemCoordinates = itemCoordinates;
    }
  },
  actions: {
    async loginUser({ commit }, userData) {
      const response = await userServices.login(userData); 
      commit('SET_USER', response);
    },
    async getItemsInfo({ commit }, name) {
      const response = await itemServices.getItemsInfo(name); 
      commit('SET_ITEMS', response);
    },
    async getCustomerPosition({ commit }, userId) {
      const response = await itemServices.getCustomerPosition(userId); 
      commit('SET_LOCATION', response);
    },
    async getItemCoordinates({ commit }, itemId) {
      const response = await itemServices.getItemCoordinates(itemId); 
      commit('SET_ITEM_COORDINATES', response.item_locations[0].location);
    },
  },
  getters: {
    isLogin: state => state.isLogin,
    currentUser: state => state.user,
    accessToken: state => state.token,
    customerPosition: state=> state.customerPosition,
    itemCoordinates: state=> state.itemCoordinates,
    itemInfos: state=> state.items,
  }
});

export default store;
