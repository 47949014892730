import { createApp } from 'vue'
import App from './App.vue'
import store from './store';
import { v4 as uuidv4 } from 'uuid';
import crypto from './utils/crypto';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';


const app = createApp(App);

let uuid = sessionStorage.getItem('uuid');
if (!uuid) {
  let uuid = uuidv4();
  const encryptedUUID = crypto.encrypt(uuid,'key');
  sessionStorage.setItem('uuid', encryptedUUID);
  uuid = encryptedUUID;
}
const decryptUUID = crypto.decrypt(uuid,'key');

app.provide('uuid', decryptUUID);
app.use(store);

app.mount('#app');
