<template>
  <div class="container mt-5">
    <div  class="logo-container mt-5">
      <img alt="Logo" class="col-md-6" src="../assets/logo.webp">
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="handleLogin">
              <div class="form-group mb-4">
                <label for="name">Name:</label>
                <input v-model="name" type="text" class="form-control form-control-lg" id="name" required />
              </div>
              <div class="form-group mb-4">
                <label for="password">Password:</label>
                <input v-model="password" type="password" class="form-control form-control-lg" id="password" required />
              </div>
              <button type="submit" class="btn btn-primary btn-lg w-100">Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <error-modal ref="errorModal" :message="errorMessage" />
  </div>
</template>

<script>
import ErrorModal from '../components/ErrorModal.vue';

export default {
  name: 'LoginPage',
  components: {
    ErrorModal
  },
  data() {
    return {
      name: '',
      password: '',
      errorMessage: ''
    };
  },
  methods: {
    handleLogin() {
      const userData = {  
        username: this.$data.name,  
        password: this.$data.password  
      }; 
      this.$store.dispatch('loginUser', userData)  
        .then(() => {  
        })  
        .catch(error => {  
          this.errorMessage = 'Login failed: ' + error.message;
          this.$refs.errorModal.show();
        });
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 500px;
  margin-top: 100px;
}

.card-title {
  margin-bottom: 0;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
}

.btn-lg {
  padding: 0.75rem 1.25rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.logo-container {
  text-align: center;
}
</style>
