<template>
  <div class="container">
    <div class="header">
      <button class="btn btn-primary">
        {{ currentUser.name }}
      </button>
    </div>
    <div class="row mt-3">
      <div class="col-md-2"></div>
      <div class="col-md-4">
        <button type="button" @click="getCustomerLocation" class="btn btn-primary">Current Location</button>
      </div>
      <div class="col-md-6 input-group mb-3">
        <input type="text" class="form-control" v-model="inputText" placeholder="Please input the search content" aria-label="Recipient's username" aria-describedby="button-addon2">
        <button class="btn btn-outline-secondary" type="button" id="button-addon2"  @click="searchItem" >Search</button>
      </div>
    </div>
    <popup-table
      ref="popupModal" 
      @getItemCoordinates='getItemCoordinates'
     />
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8 map-canvas">
        <canvas ref="canvas" @click="handleClick"></canvas>
        <img ref="mapImage" :src="mapSrc" @load="setupCanvas" class="hidden" />
      </div>
      <div class="col-md-2"></div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import PopupTable from '../components/PopupTable.vue';

export default {
  name: 'HomePage',
  components: {
    PopupTable
  },
  data() {
    return {
      mapSrc: require('@/assets/map.png'),
      inputText: ''
    };
  },
  computed: {
    ...mapGetters(['currentUser','customerPosition','itemCoordinates','itemInfos'])
  },
  methods: {   
    ...mapActions(['getCustomerPosition','getItemCoordinates']),
    searchItem() {
      this.$store.dispatch('getItemsInfo', this.inputText)  
        .then(() => {
          this.isModalOpen = true;
          this.$refs.popupModal.show();
        });
    },
    async getItemCoordinates() {
      this.drawCoordinates();
    },
    async getCustomerLocation() {
      await this.getCustomerPosition(this.currentUser.id); 
      this.drawCoordinates();
    },
    setupCanvas() {
      const canvas = this.$refs.canvas;
      const img = this.$refs.mapImage;
      canvas.width = img.width;
      canvas.height = img.height;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, img.width, img.height);
      context.fill();
    },
    drawCoordinates() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      const img = this.$refs.mapImage;
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(img, 0, 0, img.width, img.height);
      if(this.itemCoordinates != null){
        const x = this.itemCoordinates[0];
        const y =  this.itemCoordinates[1];
        context.beginPath();
        context.arc(x, y, 5, 0, Math.PI * 2, true); // 5 是圆点半径
        context.fillStyle = 'red';
        context.fill();
      }
      if (this.customerPosition.location && this.customerPosition.location[0] && this.customerPosition.location[1]) {
        this.drawStar(context, this.customerPosition.location[0], this.customerPosition.location[1], 5, 10, 5);
      }
    },
    drawStar(context, cx, cy, spikes, outerRadius, innerRadius) {
      let rot = Math.PI / 2 * 3;
      let x = cx;
      let y = cy;
      let step = Math.PI / spikes;
      context.beginPath();
      context.moveTo(cx, cy - outerRadius);
      for (let i = 0; i < spikes; i++) {
        x = cx + Math.cos(rot) * outerRadius;
        y = cy - Math.sin(rot) * outerRadius;
        context.lineTo(x, y);
        rot += step;
        x = cx + Math.cos(rot) * innerRadius;
        y = cy - Math.sin(rot) * innerRadius;
        context.lineTo(x, y);
        rot += step;
      }
      context.lineTo(cx, cy - outerRadius);
      context.closePath();
      context.fillStyle = 'blue';
      context.fill();
    }
  }
}
</script>
<style scoped>
.container{
  max-width:1440px;
  padding-right:0px;
  padding-left:0px;
}
.header {
  height: 10%;
  background: #4095E5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}
.container, .col-12 {
  height: 100vh;
}
.header {
  height: 10%;
  background: #4095E5;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: auto !important;
}
.map-canvas {
  position: relative;
}
.hidden {
  display: none;
}
canvas {
  border: 1px solid #ccc;
  cursor: crosshair;
  width: 100%;
}

</style>